import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import Bannerz from "../components/clientbanner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import PromotionsModal from '../components/promo.jsx';
import Loader from "./Loader";
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

function Home() {

  const [showDelayedComponents, setShowDelayedComponents] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDelayedComponents(true);
    }, 3700);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);



  return (
    <>
      <Helmet>
        <title>
        Pineapple Car Rental Antigua | Car Rental Jolly Harbour Antigua | Car Hire Jolly Harbour Antigua
        </title>

        <meta
          name="description"
         content="Pineapple Car Rental Antigua is the best choice if you are looking for car rental jolly harbour antigua. We provide car hire antigua jolly harbour, Bolands car rental, Antigua rent a car"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Loader />
      <PromotionsModal />

      <Hero />
      <PickCar />
      
      {showDelayedComponents && (
         <>
      <ChooseUs />
      
      <Bannerz/>

      <Testimonials />
      <Banner />
      <Faq />
      </>
    )}
      <Footer />
    </>
  );
}

export default Home;
