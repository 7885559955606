


import React, { useEffect, useState } from "react";
import "./Loader.css"; // Import the CSS for the loader
import Logo from "../images/logo/logo2.jpg";


const Loader = () => {


  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    // Fetch user's location using a geolocation API
    const fetchLocation = async () => {
      try {
      
        //ad7eb6603fde4a0390d115dd32c24b37
        const apiKey = "5ceb081ed3b848abb73ad3c8dda46978"; // Replace with your ipgeolocation.io API key
        const apiUrl = `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
  
        console.log("User's Country:", data.country_name); // Debugging

        // Check if the user is NOT from Antigua
       
      // if (data.country_name !== "France") {
        if (data.country_name !== "Antigua and Barbuda") {
          setShowAds(true); // Show ads for non-Antigua users
        }
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
      }
    };

    fetchLocation();
  }, []);




  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Automatically hide the loader after 5 seconds
    const timer = setTimeout(() => setIsVisible(false), 6500);
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null; // Hide loader when the timer ends


  return (
    <div className="loader-overlay">
      <div className="loaders">
        <img /* style={{ width: '200px',
    height: 'auto',
    animation: 'dimEffect 2s infinite',}} */
          src={Logo} // Replace with your actual image
         // alt="Loading"
          className="loaders-image"
        />
        <h2 className="loading-text">Loading Website..</h2>
      </div>

     
      {showAds && (
      <iframe
  title="Monetag Ad"
 // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.749539212057!2d-61.8737877!3d17.0661826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c1293f1af8e3e2f%3A0x52fb8a6780ae9509!2sPineapple%20Car%20Rentals!5e0!3m2!1sen!2sus!4v1685589984306!5m2!1sen!2sus"
 src="https://www.profitablecpmrate.com/khw2uptqg3?key=449b496988bb5f5339e3236732c1d33e"

  style={{
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "30%",
    border: "none",
  }}
></iframe>
         )}

    </div>
  );
};

export default Loader;